import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export type VisitItemType = {
  id: number;
  date: string;
  anamnezId: number;
  doctor: string;
  anamnez: string;
  therapy: string;
  diagnoz: string;
  repeat: string;
  userChoice?: any;
};
type Props = {
  item: VisitItemType;
  anamnez?: any;
  key?: string;
};

export const VisitItem = ({ item, anamnez, ...props }: Props) => {
  const navigate = useNavigate();
  const { id, doctor, date, diagnoz, therapy } = item;

  const d = moment(date);

  const [userChoice, setUserChoice] = useState({});

  const [pagesBlocks, setPagesBlocks] = useState([]);

  useEffect(() => {
    if (anamnez?.data?.items) {
      let tempChoice = {};
      const data = anamnez?.data?.items;
      if (data.length && data[0]) {
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          tempChoice = { ...tempChoice, [key]: false };
        });
      }

      if (item?.userChoice) {
        tempChoice = { ...tempChoice, ...item.userChoice };
      }
      setUserChoice(tempChoice);

      const blocks = [data[0]];
      _.each(data[0].children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (tempChoice[key]) {
          if (item?.cond?.showChild != false) {
            _.each(item.children, (item2) => {
              const key2 = `code_${item2.id}`;
              //level 2
              if (
                tempChoice[key2] &&
                item2.type == "checkbox" &&
                item2.children &&
                item2.children.length
              ) {
                item2.parentName = item.name;
                blocks.push(item2);
              }
            });
          }
          if (item?.cond?.showChild == false) {
            blocks.push(item);
          }
        }
      });
      setPagesBlocks(blocks);
    }
  }, [anamnez]);

  return (
    <div
      key={props.key}
      className="visit-card"
      onClick={() => {
        navigate(`/visits/${id}`);
      }}
    >
      <p className="visit-cardtext visit-cardtitle">
        Обращение {id} от {d.format("DD.MM.YYYY")}
      </p>
      {doctor && doctor.length > 0 && (
        <p className="visit-cardtext">Врач: {doctor}</p>
      )}
      <p className="visit-cardtext">
        Анамнез болезни:{" "}
        {pagesBlocks.map((pageBlock, index) => {
          const key = `code_${pageBlock.id}`;
          const text = (item) => {
            let returnText = "";
            const key = `code_${item.id}`;
            if (item.children.length > 0) {
              _.each(item.children, (item2) => {
                const key2 = `code_${item2.id}`;
                //level 2
                if (item2.type == "s-block") {
                } else if (userChoice[key2]) {
                  if (item2.children.length > 0) {
                    if (item2.type == "select") {
                      returnText += `${item2.name}: ${userChoice[key2]}`;
                    } else {
                      returnText += `\n${item2.name}:${
                        item2.type == "selectbox" ? " " : "\n"
                      }`;
                    }
                  } else {
                    if (item2.type != "checkbox") {
                      returnText += `\n${item2.name}: ${userChoice[key2]}`;
                    } else {
                      returnText += `${item2.name} `;
                    }
                  }
                }
              });
            } else {
              if (userChoice[key]) {
                if (item.type != "checkbox") {
                  returnText += `${item.name}: ${userChoice[key]}\n`;
                } else {
                  returnText += `${item.name} `;
                }
              }
            }
            return returnText;
          };

          if (index == 0) {
            const items = pageBlock.children.map((item) => {
              if (
                (item.cond && item.cond.showMain) ||
                userChoice[`code_${item.id}`]
              ) {
                const key = `code_${item.id}`;
                if (userChoice[key] && item.type == "checkbox" && item.name) {
                  return item.name;
                }
              }
            });
            return (
              <span
                key={`block-${item.id}`}
                className="visit-cardtext"
                style={{ fontSize: 15, marginBottom: 5 }}
              >
                {items.filter(Boolean).join(", ")}
              </span>
            );
          }
        })}
      </p>
      {therapy && therapy.length > 0 && (
        <p className="visit-cardtext">Лечение: {therapy}</p>
      )}
      {diagnoz && diagnoz.length > 0 && (
        <p className="visit-cardtext">Диагноз: {diagnoz}</p>
      )}
    </div>
  );
};
