import "./style.scss";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

import { Button } from "../../components/Button";
import { ANAMNEZ_HEALTH } from "../../config/config";
import { anamnezRoutine } from "../../redux/routines/anamnezRoutine";
import { visitsRoutine } from "../../redux/routines/visitsRoutine";
import { VisitItem } from "../VisitDetail/VisitItem";
import { NoRequests } from "./NoRequests";

type Props = {};

export const HomePage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const visits = useSelector((state: any) => state.visits.visits);
  const anamnez = useSelector((state: any) => state.anamnez.anamnez);

  const [patientVisits, setPatientVisits] = useState({
    active: [],
    finished: [],
  });

  useEffect(() => {
    dispatch(anamnezRoutine({ id: ANAMNEZ_HEALTH }));
    dispatch(visitsRoutine());

    return () => {
      dispatch(anamnezRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    if (visits.isSuccess && visits?.data?.items) {
      let tempVisits = { active: [], finished: [] };
      _.each(visits?.data?.items, (visit) => {
        const { status, id } = visit;

        if (status == "closed") tempVisits.finished.push(visit);
        else tempVisits.active.push(visit);
      });
      setPatientVisits(tempVisits);
    }
  }, [visits]);

  const handleNewVisit = () => navigate("/anamnez/health");

  return (
    <div className="index-block">
      <div className="requests requests-active">
        <h2>Текущие обращения</h2>
        {visits.isSuccess && patientVisits.active.length > 0 && (
          <div className="visit-list">
            <Scrollbar maximalThumbYSize={100}>
              {patientVisits.active.map((visit) => (
                <VisitItem
                  key={`visit_${visit.id}`}
                  item={visit}
                  anamnez={anamnez}
                />
              ))}
            </Scrollbar>
          </div>
        )}

        {visits.isSuccess && visits?.data?.items.length === 0 && <NoRequests />}
        <Button
          variant="yellow"
          onClick={handleNewVisit}
          style={{ marginTop: 30 }}
        >
          Новое обращение к врачу
        </Button>
      </div>
      <div className="requests requests-nonactive">
        {visits.isSuccess && patientVisits.finished.length > 0 && (
          <>
            <h2>Завершенные обращения</h2>
            <div className="visit-list">
              <Scrollbar maximalThumbYSize={100}>
                {patientVisits.finished.map((visit) => (
                  <VisitItem
                    key={`visit_${visit.id}`}
                    item={visit}
                    anamnez={anamnez}
                  />
                ))}
              </Scrollbar>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
