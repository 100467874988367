import "react-simple-keyboard/build/css/index.css";

import React, {useRef, useState} from "react";
import Keyboard from "react-simple-keyboard";
import inputMask from "simple-keyboard-input-mask";
import layoutEn from "simple-keyboard-layouts/build/layouts/english";
import layoutRu from "simple-keyboard-layouts/build/layouts/russian";

export default function TestPage() {
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef(null);

  const onChange = (input) => {
    setInput(input);
    console.log("Input changed", input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    let input = event.target.value;
    const keyboardInput = keyboard?.current.getInput();

    /**
     * Masked keyboard value takes precedence
     * Except when deleting
     */
    if (input >= keyboardInput) {
      input = keyboardInput;
    }

    setInput(input);
    keyboard?.current.setInput(input);
  };

  layoutRu.layout.default[4] = "{lang} @ {space}";
  layoutEn.layout.default[4] = "{lang} @ {space}";
  const keyboardLayout = layout === "ru" ? layoutRu : layoutEn;

  return (
    <div className="App">
      <input
        value={input}
        placeholder={"+1 (999) 999-9999"}
        onChange={onChangeInput}
        className={"input"} // See inputMaskTargetClass below
      />
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layout}
        {...keyboardLayout}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onModulesLoaded={() => {
          console.log("Modules loaded!");
        }}
        inputMask={{
          default: {
            mask: "+1 (999) 999-9999",
            regex: /^[0-9]+$/,
          },
        }}
        // Or, if you have multiple inputs handled by simple-keybaord
        // inputMask={{
        //   default: "(99) 9999-9999"
        // }}
        modules={[inputMask]}
        // Optional ( If you want to handle *physical* keyboard typing):
        inputMaskPhysicalKeyboardHandling={true}
        inputMaskTargetClass={"input"} // Related to "inputMaskPhysicalKeyboardHandling". The input element handled by simple-keyboard must have this class.
      />
    </div>
  );
}
