import {put} from "redux-saga/effects";

import {createSaga} from "../../utils/createSaga";
import {tokenRoutine} from "../routines/tokenRoutine";
import {userChangePasswordRoutine, userForgotRoutine, userLoginRoutine, userLogoutRoutine, userPincodeRoutine, userRefreshRoutine, userRegisterCheckRoutine, userRegisterRoutine, userRoutine, userStatusRoutine, userUpdateRoutine} from "../routines/userRoutine";

function* logout() {
  yield put(userLogoutRoutine.fulfill());
  yield put(userRefreshRoutine.fulfill());
  yield put(tokenRoutine.fulfill());
  yield put(userRoutine.fulfill());
  yield put(userStatusRoutine.fulfill());
  yield put(userPincodeRoutine.fulfill());
  //yield call(() => persistor.pause());
  // yield put({ type: "RESET" });

  // yield call(() => {
  //   //window.localStorage.clear();
  //   //persistor.persist();
  // });
  // yield call(() => {
  //   //console.log("redirect");
  //   //window.location.href = "/";
  // });
}

export default function* userSaga() {
  //yield takeLeading(userLogoutRoutine.TRIGGER, logout);
  yield createSaga(userLoginRoutine, "POST", "api/auth/login", {
    *onSuccess(response: any) {
      yield put(tokenRoutine.success(response));
      yield put(userStatusRoutine.trigger());
      //yield put(userStatusRoutine.trigger());
    },
  });

  yield createSaga(userRegisterCheckRoutine, "POST", "api/auth/registerCheck");
  yield createSaga(userRegisterRoutine, "POST", "api/auth/register", {
    *onSuccess(response: any) {
      yield put(tokenRoutine.success(response));
      yield put(userStatusRoutine.trigger());
    },
  });

  yield createSaga(userLogoutRoutine, "POST", "api/auth/logout", {
    onSuccess: logout,
  });

  yield createSaga(userForgotRoutine, "POST", "api/auth/forgot");

  yield createSaga(userRefreshRoutine, "POST", "api/auth/refresh", {
    *onSuccess(response: any, payload: any) {
      yield put(tokenRoutine.success(response));
      if (payload.routine) {
        yield put(payload.routine.trigger({...payload.data}));
      }
    },
  });

  yield createSaga(userStatusRoutine, "GET", "api/auth/status", {
    *onSuccess(response: any) {
      yield put(userRoutine.trigger());
    },
  });

  yield createSaga(userRoutine, "GET", "api/user/getInfo");

  yield createSaga(userUpdateRoutine, "POST", "api/user/update", {
    *onSuccess(response: any) {
      yield put(userRoutine.trigger());
    },
  });

  yield createSaga(userChangePasswordRoutine, "POST", "api/user/changePassword");
}
