import './style.scss';

import classnames from 'classnames';
import { log } from 'console';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import * as yup from 'yup';

import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem
} from '@mui/material';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { ANAMNEZ_HEALTH, ANAMNEZ_LIFE } from '../../config/config';
import { anamnezRoutine, userAnamnezRoutine } from '../../redux/routines/anamnezRoutine';
import { userLogoutRoutine } from '../../redux/routines/userRoutine';
import { visitsAddRoutine } from '../../redux/routines/visitsRoutine';
import { AnamnezItem } from './AnamnezItem';

type Props = {};

interface mainBlockI {
  [key: string]: any;
}

export const AnamnezDetailPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();  

  const anamnez = useSelector((state: any) => state.anamnez.anamnez);
  const userAnamnezSave = useSelector((state: any) => state.anamnez.userAnamnez);

  const [mainBlock, setMainBlock] = useState<mainBlockI>({});
  const [userChoice, setUserChoice] = useState({});
  const [renderBlocks, setRenderBlocks] = useState(false);

  const [title, setTitle] = useState("");

  const [pages, setPages] = useState(1);
  const [curPage, setCurPage] = useState(0);

  const [pagesBlocks, setPagesBlocks] = useState([]);
  const [pagesBlocksError, setPagesBlocksError] = useState<string[]>([]);
  const [showAnamnezRight, setShowAnamnezRight] = useState(false);

  const [showResult, setShowResult] = useState(false);
  const [formErrors, setErrors] = useState({});

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const onClose = () => showAlert(false);

  const scrollRef = useRef(null);

  const [anamnezType, setAnamnezType] = useState("");
  const [anamnezId, setAnamnezId] = useState(0);

  const [validationRules, setValidationRules] = useState({});

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const fetchData = () => {
    let aid;    
    setAnamnezType(params.type);
    if (params.type === "health") {
      aid = ANAMNEZ_HEALTH;
      let rules = {
        code_25: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("Заполните количество дней"),
      };
      setValidationRules({...rules});
    } else {
      aid = ANAMNEZ_LIFE;
    }
    setAnamnezId(aid);
    dispatch(anamnezRoutine({id: aid}));
  };

  useEffect(() => {
    dispatch(anamnezRoutine.fulfill());
    fetchData();
    return () => {
      dispatch(anamnezRoutine.fulfill());
      dispatch(userAnamnezRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [params.type]);

  useEffect(() => {
      if (anamnez?.data?.items) {
        let tempChoice = {};
        const data = anamnez?.data?.items;
        if (data.length && data[0]) {
          _.each(data[0].children, (item) => {
            const key = `code_${item.id}`;
            tempChoice = {...tempChoice, [key]: false};
          });
          if (anamnez?.data?.userChoice && params.type !== "health") {
            tempChoice = {...tempChoice, ...anamnez.data.userChoice};
          }
  
          setUserChoice(tempChoice);
          setPagesBlocks([data[0]]);
          setMainBlock(data[0]);
  
          setRenderBlocks(true);
  
          
        }
      }
    
   
    
  }, [anamnez]);

  useEffect(() => {
    if (anamnez?.data?.userChoice && params.type !== "health") {
      handleNext(userChoice, 0, false, {}, false);
      
    }
  }, [mainBlock]);

  useEffect(() => {
    if (userAnamnezSave.isSuccess) {
      if (mainBlock.id === ANAMNEZ_HEALTH) {
        dispatch(visitsAddRoutine({anamnezId: userAnamnezSave?.data?.id}));

        setAlertTitle("Спасибо!<br>Ваше обращение принято.");
        setAlertText("Оно будет обработано в ближайшее время.<br>Выйти из аккаунта или остаться?");
        showAlert(true);
      } else {
        navigate("/");
      }
    }
  }, [userAnamnezSave]);

  const handleSendAnamnez = () => {
    dispatch(userAnamnezRoutine({anamnezId: mainBlock.id, values: userChoice}));
  };
  const handleBack = () => {
    navigate("/");
  };
  const handleExit = () => {
    dispatch(userLogoutRoutine());
  };
  const handleRepeat = () => {
    setErrors({});
    setShowResult(false);
    setShowAnamnezRight(false);
    let uChoiseTemp = {};
    setUserChoice({});
    setPagesBlocks([mainBlock]);
  };

  const handleStep = async (step, values?) => {
    if (step == 1) {
      let res = await handleNext(values, 0);
      if (res !== false) {
        setShowAnamnezRight(true);
      }
    } else if (step == -1) {
      setShowAnamnezRight(false);
    }
  };

  const handleNext = async (values, index, showErrors = false, mainBlockF = {}, replaceValues = true) => {
    const validationsRules = (items, parent = {id: 0}) => {
      let rules = {};
      items.map((item) => {
        const key = `code_${item.id}`;
        if (item?.children?.length > 0) {
          const r = validationsRules(item.children, item);

          rules = {...rules, ...r};
        } else if (item.type == "input") {
          if ((item.cond && item.cond.showDays) || item.code == "year") {
          } else {
            const parentKey = `code_${parent?.id}`;
            if (item.cond && item.cond.numeric && values[parentKey]) {
              rules[key] = yup
                .string()
                .trim()
                .matches(/^[\d]+$/, `${item.name} только цифры`);
            } else if (values[parentKey]) {
              rules[key] = yup
                .string()
                .trim()
                .min(5, `${item.name} мин 5 символов`)
                .matches(/^[a-zа-я \,\.-]+$/i, `${item.name} только буквы`);
            }
          }
        }
      });
      return rules;
    };
    const rules = {
      ...validationRules,
      ...validationsRules(anamnez?.data?.items),
    };

    

    const schema = yup.object().shape(rules);
    
    let uChoiseTemp = {...userChoice, ...values};
    if(!replaceValues) uChoiseTemp = {...values, ...userChoice};
    // if (index > 0) uChoiseTemp = { ...values, ...userChoice };

    let errors = [];
    setUserChoice(uChoiseTemp);
    const blocks = [];
    let selected = 0;
    if (index == 0) {
      
      _.each(mainBlock.children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (values[key]) {
          selected++;
        }
        
        if (values[key] && item.type !== "input" && item.type !== "input2" && item.type !== "selectWL") {
          // if (item?.cond?.showChild != false) {
          // 	_.each(item.children, (item2) => {
          // 		const key2 = `code_${item2.id}`;
          // 		console.log("key2", key2);
          // 		//level 2
          // 		console.log(values[key2], item2.type, item2.children);
          // 		if (values[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
          // 			console.log("level 2", item2);
          // 			item2.parentName = item.name;
          // 			blocks.push(item2);
          // 		}
          // 	});
          // }
          // if (item?.cond?.showChild == false) {
          // 	blocks.push(item);
          // }
          blocks.push(item);
        }
      });
      
      setCurPage(0);
      setPagesBlocks([pagesBlocks[0], ...blocks]);
      setPagesBlocksError([]);

      if (anamnezId == ANAMNEZ_HEALTH) {
        try {
          await schema.validate(uChoiseTemp, {abortEarly: false});
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            const allErrors = error.inner.reduce((errors, currentValidation) => {
              errors.push(currentValidation.errors[0]);
              return errors;
            }, []);
            if (showErrors) {
              enqueueSnackbar(
                <div>
                  {allErrors.map((item) => {
                    return <p>{item as string}</p>;
                  })}
                </div>,

                {
                  variant: "error",
                }
              );
            }
          }

          return false;
        }
      }
      if (showErrors) {
        if (errors.length) {
          enqueueSnackbar(
            <div>
              {errors.map((item) => {
                return <p>{item as string}</p>;
              })}
            </div>,

            {
              variant: "error",
            }
          );
          //setPagesBlocksError([errors.join("\n")]);
          return false;
        }

        const needSelectedCount = anamnezId == ANAMNEZ_HEALTH ? 2 : 1;
        if (selected < needSelectedCount) {
          const errors = ["Отметьте другие признаки"];
          enqueueSnackbar(
            <div>
              {errors.map((item) => {
                return <p>{item as string}</p>;
              })}
            </div>,

            {
              variant: "error",
            }
          );
          //setPagesBlocksError(error);
          return false;
        }

        setShowAnamnezRight(true);
      }
    } else if (1 == index) {
      const checkAnswers = (items, parent: any = false) => {
        let count = 0;
        items.map((item) => {
          const key = `code_${item.id}`;
          if (item?.children?.length > 0 && item.type != "select") {
            const c = checkAnswers(item.children, item);
            if (values[key]) {
              if (c == 0) {
                errors = [...errors, `Заполните данные для "${item.name}"`];
              }
            }
            count += c;
          } else if (values[key]) {
            if ((parent && (parent.type == "s-block" || parent.type == "s-block-radio")) || !parent) {
              count++;
            } else if (parent && parent.type != "s-block" && parent.type != "s-block-radio" && values[`code_${parent.id}`]) {
              count++;
            }
          }
        });
        return count;
      };
      pagesBlocks.every((pageBlock, indexBlock) => {
        if (pageBlock != undefined && indexBlock >= index) {
          const count = checkAnswers(pagesBlocks[indexBlock].children);
          if (count == 0) {
            errors = [...errors, `Заполните данные для "${pagesBlocks[indexBlock].name}"`];
            return false;
          } else if (pagesBlocks[indexBlock].code == "temp" && count != 2) {
            errors = [...errors, `Заполните данные для "${pagesBlocks[indexBlock].name}"`];
            return false;
          }
        }
        return true;
      });

      if (errors.length) {
        const blockErrors = [];
        blockErrors[index] = errors.join("\n");
        setPagesBlocksError(blockErrors);
        enqueueSnackbar(
          <div>
            {errors.map((item) => {
              return <p>{item as string}</p>;
            })}
          </div>,

          {
            variant: "error",
          }
        );
        return;
      }
      try {
        let result = await schema.validate(uChoiseTemp, {abortEarly: false});

        setShowResult(true);
      } catch (validationErrors) {
        if (validationErrors instanceof yup.ValidationError) {
          const allErrors = validationErrors.inner.reduce((errors, currentValidation) => {
            errors.push(currentValidation.errors[0]);
            return errors;
          }, []);
          if (showErrors) {
            const blockErrors = [];
            blockErrors[index] = allErrors.join("\n");
            enqueueSnackbar(
              <div>
                {allErrors.map((item) => {
                  return <p>{item as string}</p>;
                })}
              </div>,

              {
                variant: "error",
              }
            );
          }
          setErrors(allErrors);
        }
      }
    }
    return true;
  };

  const renderQuestion = (item, key, index, depth, values, handleChange, handleBlur, setFieldValue) => {
    let innerStyle = {};
    if (depth == 1)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "#67B3E2",
        borderRadius: 5,
        marginBottom: 30,
      };
    if (depth >= 2)
      innerStyle = {
        padding: "23px 27px",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: 5,
        marginTop: 23,
      };
    return (
      <div key={key} style={innerStyle}>
        {item.children.map((item2, itemIndex) => {
          const key = `code_${item2.id}`;
          
          if (item2.type == "input") {
            return (
              <>
                <Field key={key} name={key} value={values && values[key] ? values[key] : ""} type="text" placeholder={item2.name} component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}></Field>
                {formErrors[key] && <p className="error">{formErrors[key]}</p>}
              </>
            );
          }
          if (item2.type == "checkbox" || item2.type == "radio") {
            return (
              <>
                <Field key={key} name={key} type="checkbox" component={Input} inputProps={{item: item2, parent: item}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, depth + 1, values, handleChange, handleBlur, setFieldValue)}
              </>
            );
          } else if (item2.type == "selectbox" || item2.type == "radiobox") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2, parent: item}} style={{marginBottom: 0}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, depth + 1, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "s-checkbox") {
            return (
              <div key={key} style={{marginTop: 0}}>
                <Field name={key} type="checkbox" component={Input} inputProps={{item: item2}} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                {values[key] && item2.children?.length > 0 && renderQuestion(item2, key, index, 3, values, handleChange, handleBlur, setFieldValue)}
              </div>
            );
          } else if (item2.type == "select") {
            const options = [];
            _.each(item2.children, (option) => {
              options.push({value: option.name, label: option.name});
            });
            return (
              options.length > 0 && (
                <div key={key} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {marginBottom: 16}}>
                  <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == item.children.length - 1 ? {marginBottom: 0} : {}}>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              )
            );
          } else if (item2.type == "s-block" || item2.type == "s-block-radio") {
            return (
              <div
                key={key}
                style={{
                  marginTop: 0,
                  marginBottom: itemIndex == item.children.length - 1 ? 0 : 23,
                }}>
                <p style={{flex: 1, marginBottom: 0}}>{item2.name}:</p>
                {item2.children?.length > 0 &&
                  renderQuestion(
                    item2,
                    key,
                    index,
                    depth + 1,
                    values,
                    handleChange,
                    handleBlur,

                    setFieldValue
                  )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderBlock = (condition, index) => {
    return (
      <div key={`block-${index}`} className="anamnez-block-inner">
        <Formik
          enableReinitialize={true}
          initialValues={userChoice}
          onSubmit={(values) => {
            handleNext(values, index, true);
          }}>
          {({values, errors, handleChange, handleBlur, setFieldValue}) => (
            <Form
              onChange={(e) => {
                if (index == 0) {
                  let target = e.target as HTMLButtonElement;
                  if (target.type == "checkbox") {
                    let target = e.target as HTMLInputElement;
                    values[target.name] = target.checked;
                  } else {
                    values[target.name] = target.value;
                  }
                  handleNext(values, index);
                }
              }}>
              <Scrollbar permanentTrackY={index == 0}>
                {pagesBlocks.map(
                  (pageBlock, index) =>
                    pageBlock != undefined &&
                    condition(index) && (
                      <div key={`block-${index}`}>
                        {index !== 0 && <p>{pageBlock.name}</p>}
                        <div
                          style={
                            index >= 1
                              ? {
                                  padding: "23px 27px",
                                  backgroundColor: "rgba(255,255,255,0.3)",
                                  borderRadius: 5,
                                  marginBottom: 23,
                                }
                              : {}
                          }>
                          {pageBlock.children.map((item, itemIndex) => {
                            const key = `code_${item.id}`;

                            let options = [];
                            if (item.type == "select")
                              _.each(item.children, (option) => {
                                options.push({
                                  value: option.name,
                                  label: option.name,
                                });
                              });

                            if (index > 0 && item.type == "input") {
                              item.type = "input2";
                            }

                            if (item.cond && item.cond.showDays) {
                              item.type = "selectWL";
                              let min = 1,
                                max = Number(item.cond.daysCount);

                              if (!max) {
                                if (userChoice[item.cond.daysCount]) {
                                  max = Number(userChoice[item.cond.daysCount]);
                                } else {
                                  max = 1;
                                }
                              }
                              options = _.range(min, max + 1).map((item) => ({
                                value: item,
                                label: item,
                              }));
                            }
                           

                            return (
                              <div key={key}>
                                {(item.type == "checkbox" || item.type == "radio") && (
                                  <>
                                    <Field name={key} type="checkbox" component={Input} inputProps={{item, parent: pageBlock}}></Field>

                                    {values[key] &&
                                      index != 0 &&
                                      item.children?.length > 0 &&
                                      item?.cond?.showChild != false &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                                {item.type == "input" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <label
                                      style={{
                                        flex: 1,
                                        fontSize: 16,
                                        lineHeight: "19px",
                                        color: "#FFFFFF",
                                      }}>
                                      {item.name}:
                                    </label>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} component={Input} style={{width: 80, marginLeft: 20}}></Field>
                                  </div>
                                )}
                                {item.type == "input2" && (
                                  <div>
                                    <Field name={key} type="text" value={values && values[key] ? values[key] : ""} placeholder={item.name} component={Input} style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}></Field>
                                    {formErrors[key] && <p className="error">{formErrors[key]}</p>}
                                  </div>
                                )}
                                {item.type == "select" && (
                                  <>
                                    {options.length > 0 && (
                                      <div
                                        key={key}
                                        style={{
                                          marginTop: 16,
                                        }}>
                                        <Field name={key} type="select" value={values && values[key] ? values[key] : ""} placeholder="выбрать" component={Input} style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}>
                                          {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </div>
                                    )}
                                  </>
                                )}
                                {item.type == "selectWL" && (
                                  <>
                                    {options.length > 0 && (
                                      <div
                                        key={key}
                                        style={{
                                          marginTop: 16,
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <label
                                          style={{
                                            flex: 1,
                                            fontSize: 16,
                                            lineHeight: "19px",
                                            color: "#FFFFFF",
                                          }}>
                                          {item.name}:
                                        </label>
                                        <Field
                                          name={key}
                                          type="select"
                                          value={values && values[key] ? values[key] : ""}
                                          placeholder="выбрать"
                                          component={Input}
                                          style={itemIndex == pageBlock.children.length - 1 ? {marginBottom: 0} : {}}
                                          inputProps={{
                                            selectStyle: {maxHeight: 300},
                                          }}>
                                          {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </Field>
                                      </div>
                                    )}
                                  </>
                                )}
                                {(item.type == "selectbox" || item.type == "radiobox") && (
                                  <>
                                    <Field
                                      name={key}
                                      type="checkbox"
                                      component={Input}
                                      inputProps={{
                                        item: item,
                                        parent: pageBlock,
                                      }}
                                      style={{marginBottom: 16}}></Field>
                                    {values[key] && item.children?.length > 0 && renderQuestion(item, key, index, 2, values, handleChange, handleBlur, setFieldValue)}
                                  </>
                                )}
                                {(item.type == "s-block" || item.type == "s-block-radio") && (
                                  <>
                                    <p style={{flex: 1}}>{item.name}:</p>
                                    {item.children?.length > 0 &&
                                      renderQuestion(
                                        item,
                                        key,
                                        index,
                                        1,
                                        values,
                                        handleChange,
                                        handleBlur,

                                        setFieldValue
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                )}
              </Scrollbar>
              <div className="btn-row">
                {index === 0 && !showAnamnezRight && (
                  <>
                    <Button type="submit">Далее</Button>
                    {pagesBlocksError[curPage] && (
                      <div
                        style={{
                          backgroundColor: "#f8d7da",
                          padding: "10px 20px",
                          borderRadius: 5,
                        }}>
                        <p style={{color: "#721c24"}}>{pagesBlocksError[curPage]}</p>
                      </div>
                    )}
                  </>
                )}
                {showAnamnezRight && index !== 0 && (
                  <>
                    <Button onClick={() => handleStep(-1)}>Назад</Button>

                    <Button variant="yellow" type="submit">
                      {anamnezType === "health" ? "К врачу" : "Cохранить"}
                    </Button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="anamnez-detail">
      {!showResult && (
        <>
          <h1>{mainBlock.name}</h1>
          <div className="anamnez-list">
            <div
              className={classnames("anamnez-block", "anamnez-left", {
                hidden: showAnamnezRight,
              })}>
              {renderBlocks && renderBlock((index) => index == 0, 0)}
            </div>
            <div
              className={classnames("anamnez-block", "anamnez-right", {
                hidden: !showAnamnezRight,
              })}>
              <div className="scroller" ref={scrollRef}>
                <div style={{display: "flex", height: "100%"}}>{renderBlocks && renderBlock((index) => index > 0, 1)}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {showResult && (
        <>
          <h1>Результат опроса</h1>
          <div className="anamnez-result">
            <Scrollbar>
              <div
                style={{
                  padding: "0 36px 23px",
                  flex: 1,
                }}>
                {pagesBlocks.map((pageBlock, index) => {
                  const key = `code_${pageBlock.id}`;

                  const text = (item, itemIndex = 1) => {
                    let returnText = "";
                    const key = `code_${item.id}`;
                    if (item.children.length > 0) {
                      let visibleIndex = 0;
                      _.each(item.children, (item2, index2) => {
                        const key2 = `code_${item2.id}`;
                        //level 2
                        if (item2.type == "s-block" || item2.type == "s-block-radio") {
                          const blockText = text(item2);
                          if (blockText) {
                            returnText += `${item2.name}:<br />${blockText}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                          }
                        } else if (userChoice[key2]) {
                          if (item2.children.length > 0) {
                            if (item2.type == "select") {
                              returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                            } else {
                              returnText += `${itemIndex == 2 || (item2.type != "selectbox" && item2.type != "radiobox") ? "<br />" : ""}${item2.name}:${item2.type == "selectbox" || item2.type == "radiotbox" ? " " : "<br />"}` + text(item2, itemIndex + 1);
                            }
                          } else {
                            if (item2.type != "checkbox" && item2.type != "radio") {
                              returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br />" : "<br />"}`;
                            } else {
                              returnText += `${item2.name}${Number(index2) != item.children.length - 1 && itemIndex != 1 ? ", " : "<br>"}`;
                            }
                          }
                          if (userChoice[key2]) {
                            visibleIndex++;
                          }
                        }
                      });
                    } else {
                      if (userChoice[key]) {
                        if (item.type != "checkbox") {
                          returnText += `${item.name}: ${userChoice[key]}<br />`;
                        } else {
                          returnText += `${item.name} `;
                        }
                      }
                    }
                    return returnText;
                  };

                  if (userChoice[key]) {
                    const resultItem = {
                      title: pageBlock.name,
                      text: text(pageBlock),
                    };
                    return (
                      <AnamnezItem
                        item={resultItem}
                        index={index}
                        onSelect={(index) => {
                          setShowResult(false);
                        }}
                      />
                    );
                  }
                })}
                <div className="btn-row">
                  <Button onClick={() => handleRepeat()}>Пройти заново</Button>
                  <Button variant="yellow" onClick={() => handleSendAnamnez()}>
                    {anamnezType === "health" ? "К врачу" : "Cохранить"}
                  </Button>
                </div>
              </div>
            </Scrollbar>
          </div>
        </>
      )}
      <Dialog
        open={alert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            padding: "34px 70px 50px",
            overflowY: "unset",
            background: "linear-gradient(180deg, #0087DB 0%, #0776BB 100%)",
            borderRadius: "5px",
          },
        }}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{color: "#fff", textAlign: "center"}}
            dangerouslySetInnerHTML={{
              __html: alertTitle,
            }}></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{color: "#fff", textAlign: "center"}}
              dangerouslySetInnerHTML={{
                __html: alertText,
              }}></DialogContentText>
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button variant="yellow" style={{padding: "0 50px"}} onClick={handleExit}>
              Выйти
            </Button>
            <Button style={{padding: "0 50px"}} onClick={handleBack}>
              Остаться
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
