import "./style.scss";

import {Field, Form, Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";

import PasswordIcon from "../../assets/images/eye-ico.svg";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {UserProfile} from "../../config/flow/user";
import {userChangePasswordRoutine} from "../../redux/routines/userRoutine";
import {IRemoteData} from "../../utils/RemoteData";
import {useTogglePasswordVisibility} from "../../utils/useTogglePasswordVisibility";
import {passwordSchema} from "./schema";

type Props = {};

interface IState {
  user: {user: IRemoteData<UserProfile>; userUpdate: IRemoteData};
}

export const ProfileSecurityPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {passwordVisibility, handlePasswordVisibility} = useTogglePasswordVisibility();

  const user = useSelector((state: IState) => state.user.user);

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const onClose = () => showAlert(false);

  const userChangePassword = useSelector((state: any) => state.user.userChangePassword);

  useEffect(() => {
    if (userChangePassword.isSuccess) {
      enqueueSnackbar("Пароль изменен", {variant: "success"});
      dispatch(userChangePasswordRoutine.fulfill());
      navigate(-1);
    }
    if (userChangePassword.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userChangePassword.errorMessage);
      showAlert(true);
    }
  }, [userChangePassword]);

  useEffect(() => {
    return () => {
      dispatch(userChangePasswordRoutine.fulfill());
    };
  }, []);

  return (
    <>
      <div>
        <h1>Настройки безопасности</h1>
        <Formik
          initialValues={{
            email: user?.data?.email,
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={passwordSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            dispatch(userChangePasswordRoutine(values));
          }}>
          {({values, errors, touched}) => (
            <Form className="profile-form">
              <div>
                <Field id="email" name="email" placeholder="E-mail" component={Input} />
                <Field
                  id="oldPassword"
                  name="oldPassword"
                  type={passwordVisibility ? "password" : "text"}
                  placeholder="Старый пароль"
                  component={Input}
                  inputProps={{
                    rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                  }}
                />
                <Field
                  id="password"
                  name="password"
                  type={passwordVisibility ? "password" : "text"}
                  placeholder="Пароль"
                  component={Input}
                  inputProps={{
                    rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                  }}
                />
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type={passwordVisibility ? "password" : "text"}
                  placeholder="Подтверждение пароля"
                  component={Input}
                  inputProps={{
                    rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                  }}
                />

                <Button type="submit" style={{minWidth: 244}}>
                  Сохранить
                </Button>
                {Object.values(errors).length > 0 && (
                  <div className="form-errors">
                    {Object.values(errors).map((msg, index) => (
                      <p key={`err_${index}`}>{msg}</p>
                    ))}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <Dialog open={alert} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
          <div style={{overflowY: "unset"}}>
            <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <IconButton
                color="primary"
                sx={{
                  position: "absolute",
                  right: "-20px",
                  top: "-20px",
                  backgroundColor: "lightgray",
                  color: "gray",
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
};
