import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import { anamnezRoutine, userAnamnezRoutine } from "../routines/anamnezRoutine";

const persistConfig = {
  key: "anamnez",
  blacklist: ["anamnez", "userAnamnez"]
};

export default createPersistedReducer(
  combineReducers({
    anamnez: createReducer(anamnezRoutine, "anamnez"),
    userAnamnez: createReducer(userAnamnezRoutine, "userAnamnez")
  }),
  persistConfig
);
