import classNames from "classnames";
import React from "react";
import EditIco from "../../assets/images/edit-ico.svg";
import "./style.scss";

export type Item = {
  title: string;
  text: string;
};

type Props = {
  item: Item;
  index: number;
  onSelect?: (index: number) => void;
  size?: "small";
};

export const AnamnezItem = ({ item, index, onSelect, size }: Props) => {
  const { title, text } = item;
  return (
    <div
      className={classNames("anamnez-card", {
        "anamnez-cardSmall": size === "small",
      })}
    >
      <div
        className={classNames("anamnez-titleRow", {
          "anamnez-titleRowSmall": size === "small",
        })}
      >
        <p
          className={classNames("anamnez-cardText", "anamnez-cardTitle", {
            "anamnez-cardTitleSmall": size === "small",
          })}
        >
          {title}
        </p>
        {onSelect && (
          <a
            onClick={() => {
              onSelect(index);
            }}
          >
            <img src={EditIco} style={{ width: 13, height: 14 }} />
          </a>
        )}
      </div>
      <p
        className={classNames("anamnez-cardText", {
          "anamnez-cardTextSmall": size == "small",
        })}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></p>
    </div>
  );
};
