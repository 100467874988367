import "moment/locale/ru";
import "./style.scss";

import {Field, Form, Formik, useFormikContext} from "formik";
import moment from "moment";
import {useSnackbar} from "notistack";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem} from "@mui/material";

import PasswordIcon from "../../assets/images/eye-ico.svg";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {hospitalRoutine} from "../../redux/routines/hospitalRoutine";
import {userRegisterCheckRoutine, userRegisterRoutine} from "../../redux/routines/userRoutine";
import {useKeyboardLang} from "../../utils/useKeyboardLang";
import {useTogglePasswordVisibility} from "../../utils/useTogglePasswordVisibility";
import {registerSchema, registerSchema2} from "./schema";

moment.locale("ru");

type Props = {};

const gender = [
  {value: "male", label: "Мужской"},
  {value: "female", label: "Женский"},
];

const SnackErrors = () => {
  const {errors, submitCount, isSubmitting} = useFormikContext();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    clearTimeout(timer);
    let t = setTimeout(() => {
      clearTimeout(timer);
      if (Object.values(errors).length > 0) {
        enqueueSnackbar(
          <div>
            {Object.values(errors).map((item) => {
              return <p>{item as string}</p>;
            })}
          </div>,

          {
            variant: "error",
          }
        );
      }
    }, 500);
    setTimer(t);
  }, [submitCount]);

  return null;
};

export const RegisterPage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {passwordVisibility, handlePasswordVisibility} = useTogglePasswordVisibility();

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");

  const userRegister = useSelector((state: any) => state.user.userRegister);
  const userRegisterCheck = useSelector((state: any) => state.user.userRegisterCheck);

  const hospitals = useSelector((state: any) => state.hospital.hospitals);
  const [hospitalsSelect, setHospitalsSelect] = useState([]);

  const customInputRef = useRef<HTMLInputElement>();
  const [dateRepeatOpen, setDateRepeatOpen] = useState(false);

  const [step, setStep] = useState(1);
  const [stepValues, setStepValues] = useState({});

  const onClose = () => showAlert(false);

  useEffect(() => {
    dispatch(hospitalRoutine());
    return () => {
      setStep(1);
      setStepValues({});
    };
  }, []);

  useEffect(() => {
    if (hospitals?.data?.items) {
      let hospitalsTemp = [];
      hospitals?.data?.items.map((item) => {
        if (item.status == "active") hospitalsTemp.push({value: item.id, label: item.name});
      });

      setHospitalsSelect(hospitalsTemp);
    }
  }, [hospitals]);

  useEffect(() => {
    if (userRegister.isSuccess) {
      navigate("/");
    }
    if (userRegister.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userRegister.errorMessage);
      showAlert(true);
    }
  }, [userRegister]);

  useEffect(() => {
    if (userRegisterCheck.isSuccess) {
      setStep(2);
    }
    if (userRegisterCheck.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userRegisterCheck.errorMessage);
      showAlert(true);
    }
  }, [userRegisterCheck]);

  return (
    <div className="container" id="RegisterPage">
      {step === 1 && (
        <>
          <h1>Регистрация аккаунта</h1>
          <Formik
            initialValues={{
              lastname: "",
              name: "",
              secondname: "",
              birthday: "",
              gender: "",
              oms: "",
              address: "",
              hospital: "",
              agree: false,
            }}
            validationSchema={registerSchema}
            //validateOnBlur={false}
            //validateOnChange={false}
            onSubmit={(values) => {
              let send = {...values};
              send.birthday = moment(send.birthday).format("DD.MM.YYYY");
              //dispatch(userRegisterRoutine(send));
              setStepValues(send);
              dispatch(userRegisterCheckRoutine(send));
            }}>
            {({values, errors, touched, setFieldValue}) => (
              <Form className="register-form">
                <div className="column">
                  <Field id="lastname" name="lastname" placeholder="Фамилия" component={Input} autoFocus />
                  <Field id="name" name="name" placeholder="Имя" component={Input} />
                  <Field id="secondname" name="secondname" placeholder="Отчество" component={Input} />

                  <Field id="birthday" name="birthday" placeholder="День рождения" mask="99.99.9999" component={Input} />

                  <Field
                    name="agree"
                    type="checkbox"
                    component={Input}
                    inputProps={{
                      item: {
                        name: <a href="/policy">Даю согласие на обработку персональных данных</a>,
                      },
                    }}></Field>
                </div>
                <div className="column">
                  <Field id="gender" name="gender" placeholder="Ваш пол" type="select" value={values.gender} component={Input}>
                    {gender.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field id="oms" name="oms" placeholder="Номер полиса ОМС" component={Input} />
                  <Field id="address" name="address" placeholder="Адрес проживания" component={Input} />
                  <Field id="hospital" name="hospital" type="select" value={values.hospital} placeholder="Поликлиника" component={Input}>
                    {hospitalsSelect.map((hospital) => (
                      <MenuItem key={hospital.value} value={hospital.value}>
                        {hospital.label}
                      </MenuItem>
                    ))}
                  </Field>

                  <Button type="submit">Зарегистрироваться</Button>
                </div>

                <SnackErrors />
              </Form>
            )}
          </Formik>
        </>
      )}
      {step === 2 && (
        <>
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={registerSchema2}
            onSubmit={(values) => {
              dispatch(userRegisterRoutine({...stepValues, ...values}));
            }}>
            {({handleChange, handleBlur, handleSubmit, values, errors, submitCount}) => (
              <Form className="register-form">
                <div className="column one">
                  <h1>Завершающий этап</h1>
                  <p>Введите адрес электронной почты, который вы будете использовать для входа в свой личный кабинет</p>
                  <Field id="email" name="email" placeholder="Ваш email" lang="en" component={Input} />
                  <h2>Пароль для входа</h2>
                  <p>Введите пароль, который вы хотите использовать для входа в свой личный кабинет</p>
                  <Field
                    id="password"
                    name="password"
                    lang="en"
                    type={passwordVisibility ? "password" : "text"}
                    placeholder="Пароль"
                    component={Input}
                    inputProps={{
                      rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                    }}
                  />
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    lang="en"
                    type={passwordVisibility ? "password" : "text"}
                    placeholder="Подтверждение пароля"
                    component={Input}
                    inputProps={{
                      rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} />,
                    }}
                  />
                  <Button type="submit">Завершить регистрацию</Button>
                </div>
                <SnackErrors />
              </Form>
            )}
          </Formik>
        </>
      )}
      <Dialog open={alert} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{sx: {overflowY: "unset"}}}>
        <div style={{overflowY: "unset"}}>
          <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: "-20px",
                top: "-20px",
                backgroundColor: "lightgray",
                color: "gray",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
