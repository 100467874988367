import './style.scss';

import { log } from 'console';
import { FieldInputProps, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { Checkbox, CheckboxProps, FormControlLabel, Select, SelectProps } from '@mui/material';

import Dropdown from '../../assets/images/dropdown-ico.svg';
import KeyboardWrapper from './KeyboardWrapper';

type Props = {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  inputProps: any;
  type: string;
  children?: JSX.Element;
  style?: any;
  placeholder?: string;
  value?: any;
  mask?: string;
  lang?: string;
};

export const Input = ({field, form, inputProps, style, mask, lang = "ru", ...props}: Props & (React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & CheckboxProps & SelectProps)) => {
  const [input, setInput] = useState("");
  const [keyBoardVisible, setKeyBoardVisible] = useState(false);
  const keyboard = useRef(null);

  useEffect(() => {
    if(field.value && input === "") {
      setInput(field.value);
    }
    function clickHanlder(e) {
      if (!e.target.classList.contains("hg-button") && e.target.name != field.name) {
        setKeyBoardVisible(false);
      }
    }
    setTimeout(() => {
      if (props.autoFocus) {
        setKeyBoardVisible(true);
      }
    }, 100);
    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, []);

  if (props.type == "checkbox" || props.type == "radio") {
    let type = props.type;
    props.type = "checkbox";
    return (
      <div className="checkbox-row" style={{...style}}>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...props}
              onChange={(e) => {
                if (inputProps && inputProps.parent && (inputProps.parent.type == "radio" || inputProps.parent.type == "s-block-radio" || inputProps.parent.type == "radiobox")) {
                  inputProps.parent.children.map((ch) => {
                    form.setFieldValue(`code_${ch.id}`, false);
                  });
                }
                form.setFieldValue(field.name, e.target.checked);
              }}
              icon={<span className="checkbox-custom"></span>}
              checkedIcon={<span className="checkbox-custom checked"></span>}
            />
          }
          label={inputProps.item.name}
        />
      </div>
    );
  }
  if (props.type == "select") {
    return (
      <div className="form-control-row select-row" style={{...style}}>
        <Select
          {...field}
          {...props}
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.value);
          }}
          IconComponent={() => <img src={Dropdown} style={{position: "absolute", right: 20, pointerEvents: "none"}} />}
          sx={{
            backgroundColor: "#fff",
            border: "none",
            width: "100%",
            height: 64,
          }}
          MenuProps={{
            sx: {width: "100%"},
            PaperProps: inputProps && inputProps.selectStyle ? {sx: inputProps.selectStyle} : {},
          }}
          displayEmpty
          renderValue={props.value !== "" ? undefined : () => props.placeholder}>
          {props.children}
        </Select>
      </div>
    );
  }
  return (
    <div className="form-control-row" style={{...style}}>
      <input
        {...field}
        {...props}
        onChange={(e) => {
          if (props?.onChange) {
            props?.onChange(e);
          }
          let inputValue = e.target.value;
          const keyboardInput = keyboard?.current.getInput();

          if (inputValue >= keyboardInput && mask && mask != "") {
            inputValue = keyboardInput;
          }

          console.log("keyboardInput", inputValue, keyboardInput, mask);
          setInput(inputValue);
          keyboard?.current.setInput(inputValue);
          form.setFieldValue(field.name, inputValue);
        }}
        onFocus={() => {
          setKeyBoardVisible(true);

          //keyboard.current.setInput(form.get);
        }}
        value={input}
        className={`form-control form-control-${field.name}`}
        autoComplete="new-password"
      />

      <KeyboardWrapper
        keyboardRef={keyboard}
        onChange={(val) => {
          console.log("@kyebchange", val);

          setInput(val);
          form.setFieldValue(field.name, val);
        }}
        fieldName={field.name}
        visible={keyBoardVisible}
        mask={mask}
        lang={lang}
      />

      {inputProps && inputProps.rightIcon && <div className="form-control-icon">{inputProps.rightIcon}</div>}
    </div>
  );
};
