import "./style.scss";

import React, { useEffect, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { hospitalRoutine } from "../../redux/routines/hospitalRoutine";
import { userLogoutRoutine } from "../../redux/routines/userRoutine";
import { Button } from "../Button";

type Props = {};

const gender = {
  male: "Мужской",
  female: "Женский",
};

export const LeftSide = (props: Props) => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user.user);
  const hospitals = useSelector((state: any) => state.hospital.hospitals);

  const [hospitalsData, setHospitalsData] = useState([]);

  const handleLogout = () => {
    dispatch(userLogoutRoutine());
  };

  useEffect(() => {
    dispatch(hospitalRoutine());
  }, []);

  useEffect(() => {
    if (hospitals.isSuccess && hospitals?.data?.items) {
      let hospitalsData: any = {};

      hospitals?.data?.items.map((item) => {
        hospitalsData[item.id] = item.name;
      });

      setHospitalsData(hospitalsData);
    }
  }, [hospitals]);

  return (
    <>
      <div className="left-side">
        <div className="block user-block">
          <p className="title">Пациент: ID {user?.data?.code}</p>
          <p className="text">
            {user?.data?.lastname} {user?.data?.name} {user?.data?.secondname}
          </p>
          <p className="text">Пол: {gender[user?.data?.gender]}</p>
          <p className="text">
            Дата рождения: {moment(user?.data?.birthday).format("DD.MM.YYYY")}г
          </p>
          <p className="text">{hospitalsData[user?.data?.hospital]}</p>
        </div>
        <Link to="/profile" className="block menu-link">
          <span>Аккаунт</span>
          <span>Редактирование параметров учетной записи</span>
        </Link>
        <Link to="/anamnez/life" className="block menu-link">
          <span>Анамнез жизни</span>
          <span>Редактирование данных о заболеваниях</span>
        </Link>
        <Button
          variant="yellow"
          style={{ width: "100%", marginTop: "auto" }}
          onClick={handleLogout}
        >
          Выйти
        </Button>
      </div>
    </>
  );
};
