import "./style.scss";

import {Field, Form, Formik} from "formik";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {MenuItem} from "@mui/material";

import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {hospitalRoutine} from "../../redux/routines/hospitalRoutine";
import {userUpdateRoutine} from "../../redux/routines/userRoutine";
import {profileSchema} from "./schema";

type Props = {};

const gender = [
  {value: "male", label: "Мужской"},
  {value: "female", label: "Женский"},
];

export const ProfilePage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const user = useSelector((state: any) => state.user.user);
  const userUpdate = useSelector((state: any) => state.user.userUpdate);

  const hospitals = useSelector((state: any) => state.hospital.hospitals);
  const [hospitalsSelect, setHospitalsSelect] = useState([]);

  useEffect(() => {
    dispatch(hospitalRoutine());
  }, []);

  useEffect(() => {
    if (hospitals?.data?.items) {
      let hospitalsTemp = [];
      hospitals?.data?.items.map((item) => {
        if (item.status == "active") hospitalsTemp.push({value: item.id, label: item.name});
      });

      setHospitalsSelect(hospitalsTemp);
    }
  }, [hospitals]);

  useEffect(() => {
    if (userUpdate.isSuccess) {
      enqueueSnackbar("Данные изменены", {variant: "success"});
      dispatch(userUpdateRoutine.fulfill());
    }
  }, [userUpdate]);

  const handleSecurity = () => {
    navigate("/profile/security");
  };

  return (
    <>
      <div>
        <h1>Аккаунт</h1>
        <Formik
          initialValues={{
            lastname: user?.data?.lastname || "",
            name: user?.data?.name || "",
            secondname: user?.data?.secondname || "",
            oms: user?.data?.oms || "",
            address: user?.data?.address || "",
            hospital: user?.data?.hospital || "",
            email: user?.data?.email || "",
            gender: user?.data?.gender || "",
          }}
          validationSchema={profileSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            dispatch(userUpdateRoutine(values));
          }}>
          {({values, errors, touched}) => (
            <>
              <Form className="profile-form">
                <div>
                  <Field id="lastname" name="lastname" placeholder="Фамилия" component={Input} />
                  <Field id="name" name="name" placeholder="Имя" component={Input} />
                  <Field id="secondname" name="secondname" placeholder="Отчество" component={Input} />
                  <Field id="gender" name="gender" type="select" value={values.gender} placeholder="Ваш пол" component={Input}>
                    {gender.map((gender) => (
                      <MenuItem key={gender.value} value={gender.value}>
                        {gender.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <br />
                  <Button type="submit" style={{minWidth: 244}}>
                    Сохранить
                  </Button>
                </div>
                <div>
                  <Field id="oms" name="oms" placeholder="Номер полиса ОМС" component={Input} />
                  <Field id="address" name="address" placeholder="Адрес проживания" component={Input} />
                  <Field id="hospital" name="hospital" type="select" value={values.hospital} placeholder="Ваш пол" component={Input}>
                    {hospitalsSelect.map((hospital) => (
                      <MenuItem key={hospital.value} value={hospital.value}>
                        {hospital.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Button onClick={handleSecurity} style={{minWidth: 244}}>
                    Настройки безопасности
                  </Button>
                </div>
              </Form>
              {Object.values(errors).length > 0 && (
                <div className="form-errors">
                  {Object.values(errors).map((msg: string, index) => (
                    <p key={`err_${index}`}>{msg}</p>
                  ))}
                </div>
              )}
              {userUpdate.isFailure && userUpdate.errorMessage && (
                <div className="form-errors">
                  <p>{userUpdate.errorMessage}</p>
                </div>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
