import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import { tokenRoutine } from "../routines/tokenRoutine";
import {
  userChangePasswordRoutine,
  userForgotRoutine,
  userLoginRoutine,
  userPincodeLoginRoutine,
  userPincodeRoutine,
  userRegisterCheckRoutine,
  userRegisterRoutine,
  userRoutine,
  userStatusRoutine,
  userUpdateRoutine,
} from "../routines/userRoutine";

const persistConfig = {
  key: "user",
  whitelist: ["auth", "user", "userPincode"],
};

export default createPersistedReducer(
  combineReducers({
    user: createReducer(userRoutine, "user", true),
    auth: createReducer(tokenRoutine, "auth", true),
    userPincode: createReducer(userPincodeRoutine, "userPincode", true),
    userPincodeLogin: createReducer(userPincodeLoginRoutine),
    userLogin: createReducer(userLoginRoutine),
    userRegister: createReducer(userRegisterRoutine),
    userRegisterCheck: createReducer(userRegisterCheckRoutine),
    userChangePassword: createReducer(userChangePasswordRoutine),
    userUpdate: createReducer(userUpdateRoutine),
    userForgot: createReducer(userForgotRoutine),
    userStatus: createReducer(userStatusRoutine),
  }),
  persistConfig
);
