import {createContext, useContext, useState} from "react";

type ContextType = {
  lang: string;
  toggleLang: () => void;
};
const KeyboardLangContext = createContext<ContextType>({
  lang: "",
  toggleLang: () => {},
});
export const KeyboardLangProvider = (prop: {value?: string; children: JSX.Element | JSX.Element[]}) => {
  const [lang, setLang] = useState(prop.value || "ru");
  const toggleLang = () => {
    if (lang === "ru") {
      setLang("en");
    } else {
      setLang("ru");
    }
  };

  return <KeyboardLangContext.Provider value={{lang, toggleLang}}>{prop.children}</KeyboardLangContext.Provider>;
};
export const useKeyboardLang = () => useContext(KeyboardLangContext) as ContextType;
