import * as yup from "yup";

export const registerSchema = yup.object().shape({
  lastname: yup.string().required("Заполните фамилию").min(2, "Слишком короткая фамилия"),
  name: yup.string().required("Заполните имя").min(2, "Слишком короткое имя"),
  secondname: yup.string(),
  birthday: yup.string().required("Заполните день рождения"),
  gender: yup.string().required("Выберите пол"),
  address: yup.string().required("Заполните адрес"),
  hospital: yup.string().required("Выберите полклинику"),
  agree: yup.bool().oneOf([true], "Дайте согласие на обработку персональных данных"),
});

export const registerSchema2 = yup.object().shape({
  email: yup.string().email("Введите корректный email").required("Заполните email"),
  password: yup.string().required("Заполните пароль").min(6, "Пароль должен быть не менее 6 символов"),
  confirmPassword: yup
    .string()
    .required("Заполните пароль")
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
});
