import "react-simple-keyboard/build/css/index.css";

import React, {MutableRefObject, useState} from "react";
import Keyboard from "react-simple-keyboard";
import inputMask from "simple-keyboard-input-mask";
import layoutEn from "simple-keyboard-layouts/build/layouts/english";
import layoutRu from "simple-keyboard-layouts/build/layouts/russian";

import {useKeyboardLang} from "../../utils/useKeyboardLang";

interface IProps {
  visible: Boolean;
  onChange: (input: string) => void;
  keyboardRef: MutableRefObject<typeof Keyboard>;
  fieldName?: string;
  mask?: string;
  lang?: string;
}

const KeyboardWrapper = ({onChange, keyboardRef, fieldName, visible, mask, lang = "ru"}: IProps) => {
  const [layoutName, setLayoutName] = useState("default");

  const {lang: kbLang, toggleLang} = useKeyboardLang();
  const [layout, setLayout] = useState(kbLang);

  console.log("@kblang", kbLang);

  const onKeyPress = (button: string) => {
    console.log("@onKeyPress", button);
    if (button === "{shift}") {
      return;
    }
    if (button === "{lang}") {
      setLayout(kbLang === "ru" ? "en" : "ru");
      toggleLang();
    }
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  };
  const style = {
    position: "fixed" as "fixed",
    zIndex: 100,
    left: 0,
    right: 0,
    bottom: 0,
    display: visible ? "block" : "none",
  };
  layoutRu.layout.default[4] = "{lang} @ {space}";
  layoutEn.layout.default[4] = "{lang} @ {space}";

  layoutRu.layout.shift[4] = "{lang} @ {space}";
  layoutEn.layout.shift[4] = "{lang} @ {space}";

  const keyboardLayout = kbLang === "ru" ? layoutRu : layoutEn;
  let dopOptions = {};

  if (mask && mask !== "") {
    dopOptions = {
      inputMask: {
        default: {
          mask: "99.99.9999",
          regex: /^[0-9]+$/,
        },
      },
      modules: [inputMask],
    };
  }

  return (
    <div style={style}>
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        layoutName={layoutName}
        onChange={onChange}
        onKeyPress={onKeyPress}
        //inputName={fieldName}
        onModulesLoaded={() => {
          console.log("Modules loaded!");
        }}
        display={{
          "{lang}": kbLang,
          "{tab}": "Tab",
          "{lock}": "Caps",
          "{shift}": "Shift",
          "{space}": kbLang == "ru" ? "Пробел" : "Space",
          "{enter}": '<i class="ico ico-enter"></i>',
          "{bksp}": '<i class="ico ico-backspace"></i>',
        }}
        {...keyboardLayout}
        {...dopOptions}
        inputMaskPhysicalKeyboardHandling={true}
        inputMaskTargetClass={`form-control-${fieldName}`}
      />
    </div>
  );
};

export default KeyboardWrapper;
