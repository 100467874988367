import Axios from "axios";
import store from "../redux/store";
import { config } from "../config/config";
import { userRefreshRoutine, userLogoutRoutine } from "../redux/routines/userRoutine";

const Api = {
	request: async (url: string, method: "GET" | "POST" | "DELETE", payload: any, auth: any) => {
		Axios.defaults.baseURL = config.domen;
		Axios.defaults.headers.common["Content-Type"] = "application/json";

		if (auth?.data?.accessToken) {
			Axios.defaults.headers.common.Authorization = `Bearer ${auth?.data?.accessToken}`;
		}
		Axios.defaults.headers.common["Accept"] = "application/json";
		const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
		//console.log(url, method, payload);
		try {
			const result = await Axios.request({
				url,
				method,
				[dataOrParams]: payload,
			});

			let json = result.data;
			return json;
		} catch (e: any) {
			console.log(e?.response);
			if (e?.response?.data) {
				return e.response.data;
			}
			return {
				error: "fetch_failed_500",
				success: false,
				error_more: "Ошибка сервера. Попробуйте позже",
			};
		}
	},
};

export default Api;
