import "./style.scss";

import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";

import {AnamnezItem} from "../Anamnez/AnamnezItem";

type Props = {};

export const VisitDetailPage = (props: Props) => {
  let params = useParams();
  const visitId = Number.parseInt(params.id);

  const visits = useSelector((state: any) => state.visits.visits);
  const anamnez = useSelector((state: any) => state.anamnez.anamnez);

  const visit = visits.data?.items.find((item) => item.id === visitId);
  const {id, doctor, date, diagnoz, therapy, userChoice: visitUserChoice, repeat, measures} = visit;
  const d = moment(date);

  const [userChoice, setUserChoice] = useState({});

  const [pagesBlocks, setPagesBlocks] = useState([]);

  useEffect(() => {
    let tempChoice = {};
    if (anamnez?.data?.items) {
      const data = anamnez?.data?.items;
      if (data.length && data[0]) {
        _.each(data[0].children, (item) => {
          const key = `code_${item.id}`;
          tempChoice = {...tempChoice, [key]: false};
        });
      }

      if (visitUserChoice) {
        tempChoice = {...tempChoice, ...visitUserChoice};
      }
      setUserChoice(tempChoice);

      const blocks = [data[0]];
      _.each(data[0].children, (item) => {
        const key = `code_${item.id}`;
        //level 1
        if (tempChoice[key]) {
          if (item?.cond?.showChild != false) {
            _.each(item.children, (item2) => {
              const key2 = `code_${item2.id}`;
              //level 2
              if (tempChoice[key2] && item2.type == "checkbox" && item2.children && item2.children.length) {
                item2.parentName = item.name;
                blocks.push(item2);
              }
            });
          }
          if (item?.cond?.showChild == false) {
            blocks.push(item);
          }
        }
      });
      setPagesBlocks(blocks);
    }
  }, [anamnez]);

  return (
    <div className="visit-detail">
      <h1>
        Обращение {id} от {d.format("DD.MM.YYYY")}
      </h1>
      <div className="visit-block-inner">
        <Scrollbar>
          {measures && (
            <div className="visit-block">
              <p style={{marginBottom: 10}}>Данные обследования</p>
              <div className="anamnez-card anamnez-cardSmall">
                <div className="anamnez-titleRow anamnez-titleRowSmall">
                  <p className="anamnez-cardText anamnez-cardTitle anamnez-cardTitleSmall">Дата обновления данных: {moment(measures?.date).format("DD.MM.YYYY HH:mm")}</p>
                </div>

                {measures?.temperature && <p className="anamnez-cardText anamnez-cardTextSmall">Температура: {measures?.temperature}</p>}
                {measures?.pulse && <p className="anamnez-cardText anamnez-cardTextSmall">Пульс: {measures?.pulse}</p>}
                {measures?.sys && <p className="anamnez-cardText anamnez-cardTextSmall">Давление систолическое: {measures?.sys}</p>}
                {measures?.dia && <p className="anamnez-cardText anamnez-cardTextSmall">Давление диастолическое: {measures?.dia}</p>}

                {measures?.height && <p className="anamnez-cardText anamnez-cardTextSmall">Рост: {measures?.height}</p>}
                {measures?.weight && <p className="anamnez-cardText anamnez-cardTextSmall">Вес: {measures?.weight}</p>}
                {measures?.imt && <p className="anamnez-cardText anamnez-cardTextSmall">ИМТ: {measures?.imt}</p>}

                {measures?.oxi && <p className="anamnez-cardText anamnez-cardTextSmall">Насыщение крови кислородом: {measures?.oxi}</p>}
                {measures?.dynamometer_right && <p className="anamnez-cardText anamnez-cardTextSmall">Динамометр(правая): {measures?.dynamometer_right}</p>}
                {measures?.dynamometer_left && <p className="anamnez-cardText anamnez-cardTextSmall">Динамометр(левая): {measures?.dynamometer_left}</p>}
              </div>
            </div>
          )}
          <div className="visit-block">
            <p style={{marginBottom: 10}}>Анамнез болезни</p>
            {pagesBlocks.map((pageBlock, index) => {
              const key = `code_${pageBlock.id}`;
              const text = (item, itemIndex = 1) => {
                let returnText = "";
                const key = `code_${item.id}`;
                if (item.children.length > 0) {
                  let visibleIndex = 0;
                  if (item.type == "s-block") returnText += "<ul>";
                  _.each(item.children, (item2, index2) => {
                    const key2 = `code_${item2.id}`;
                    //level 2
                    if (item2.type == "s-block" || item2.type == "s-block-radio") {
                      const blockText = text(item2);
                      if (blockText) {
                        returnText += `${item2.name}:${item2.type == "s-block-radio" ? " " : "<br />"}${blockText}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                      }
                    } else if (userChoice[key2]) {
                      if (item2.children.length > 0) {
                        if (item2.type == "select") {
                          returnText += `${item2.name}: ${userChoice[key2]}<br />`;
                        } else {
                          if (item.type == "s-block") returnText += `<li>${item2.name + text(item2, itemIndex + 1)}</li>`;
                          else
                            returnText +=
                              `${item2.type != "selectbox" && item2.type != "radiobox" && visibleIndex > 0 ? "<br />" : ""}${item2.name}:${item2.type == "s-block-radio" || item2.type == "selectbox" || item2.type == "radiobox" ? " " : "<br />"}` + text(item2, itemIndex + 1);
                        }
                      } else {
                        if (item2.type != "checkbox" && item2.type != "radio") {
                          if (item.type == "s-block") returnText += `<li>${item2.name}: ${userChoice[key2]}$</li>`;
                          else returnText += `${item2.name}: ${userChoice[key2]}${Number(index2) != item.children.length - 1 ? "<br />" : ""}`;
                        } else {
                          if (item.type == "s-block") returnText += `<li>${item2.name}</li>`;
                          else returnText += `${item2.name}${Number(index2) != item.children.length - 1 ? ", " : ""}`;
                        }
                      }
                      if (userChoice[key2]) {
                        visibleIndex++;
                      }
                    }
                  });
                  if (item.type == "s-block") returnText += "</ul>";
                } else {
                  if (userChoice[key]) {
                    if (item.type != "checkbox" && item.type != "radio") {
                      returnText += `${item.name}: ${userChoice[key]}<br />`;
                    } else {
                      returnText += `${item.name} `;
                    }
                  }
                }
                return returnText;
              };

              if (index == 0) {
                const items = pageBlock.children.map((item) => {
                  if (item.cond && item.cond.showMain) {
                    const resultItem = {
                      title: item.type == "checkbox" || item.type == "radio" ? item.name : "",
                      text: text(item),
                    };
                    if (resultItem.text) {
                      return <AnamnezItem key={`code_${item.id}`} item={resultItem} index={index} size="small" />;
                    }
                  }
                });
                return items;
              }
              if (userChoice[key]) {
                const resultItem = {
                  title: pageBlock.name,
                  text: text(pageBlock),
                };
                return <AnamnezItem key={key} item={resultItem} index={index} size="small" />;
              }
            })}
          </div>
          {doctor && doctor.length > 0 && (
            <div className="visit-block">
              <p>Врач: {doctor}</p>
            </div>
          )}
          {repeat && repeat.length > 0 && (
            <div className="visit-block">
              <p>Повторная явка: {repeat}</p>
            </div>
          )}
          {diagnoz && diagnoz.length > 0 && (
            <div className="visit-block">
              <p>Установленный диагноз: {diagnoz}</p>
            </div>
          )}
          {therapy && therapy.length > 0 && (
            <div className="visit-block">
              <p>Лечение: {therapy}</p>
            </div>
          )}
        </Scrollbar>
      </div>
    </div>
  );
};
