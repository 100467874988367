import React, { useEffect } from "react";
import { Link, matchPath, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

type Props = {};

export const Header = (props: Props) => {
	let location = useLocation();
	const navigate = useNavigate();

	const showBack = location.pathname != "/";
	const handleBack = () => {
		navigate(-1);
	};

	return (
		<header>
			<div className="left-part">
				{showBack && (
					<Link to="#" className="back-btn" onClick={handleBack}>
						Вернуться назад
					</Link>
				)}
			</div>
			<div className="right-part"></div>
		</header>
	);
};
